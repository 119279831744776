import { HubDBOpeningHoursType } from "./ServiceDataTypes";
import { Slope } from "./SlopeDataTypes";

export type WeatherStationType = {
  id: string;
  name: string;
  weather: {
    temperature: number;
    cloudiness: number;
    windDirection: string;
    windSpeed: number;
  };
};

export enum WeatherForecastDataParameter {
  Temperature = 'temperature',
  Weathersymbol3 = 'weathersymbol3',
}

export type WeatherForecastDataType = {
  time: string;
  parameter: WeatherForecastDataParameter;
  value: number | null;
};

export type WeatherForecastType = {
  [key: string]: {
    time: string;
    temperature: number | null;
    icon: number | null;
  };
};

export type SummerActivityFieldName = 
 | 'basejump_avoinna'
 | 'kelkkarata_avoinna'
 | 'liikennepuisto'
 | 'seikkailuratoja_avoinna'
 | 'trampoliini'
 | 'zipline_avoinna'
;

export type LeviTodaySummaryField = {
  Open: number;
  Total: number;
};

export type LeviTodaySummerActivitySummaryField =
LeviTodaySummaryField & {
  Name: SummerActivityFieldName;
}

export type LeviTodaySummaryDataType = {
  bicycleEnduroRoutes: string;
  bicycleOffroadRoutes: string;
  bicycleRoutes: string;
  bicycleTrails: LeviTodaySummaryField;
  lifts: LeviTodaySummaryField;
  skiTracks: string;
  slopeCameras: LeviTodaySummaryField;
  slopeRestaurants: LeviTodaySummaryField;
  slopes: LeviTodaySummaryField & { SnowPreparations: number };
  snowDepth: string;
  snowMobileTracks: string;
  trekkingRoutes: string;
  walkingTrails: LeviTodaySummaryField;
  weather: number;
  summerActivities: LeviTodaySummerActivitySummaryField[];
  snowParks: LeviTodaySummaryField;
};

export type HubDBIdType = {
  hubspotId: number;
  type: string;
};

export type ActivityTrailParsedEntry = Slope;

export type ActivityTrailResponseDataEntry = {
  id: number,
  number: string | undefined,
  name: {
    fi: string,
    en: string,
  },
  comment: {
    fi: string,
    en: string,
  },
  open: {
    operating: boolean,
    temporarilyClosed: boolean,
    now: boolean,
    hours: string,
    openingHours: HubDBOpeningHoursType,
  },
  category: string,
  difficulty: string,
  length: string,
  videoId: string,
  bubbles: {
    bubble_position: number[]
  },
  routeVisibility: string[], // TODO TYPE?
  seasonalAvailability: {
    winter: boolean,
    summer: boolean,
  },
  visible: boolean,
}

export type ActivityTrailResponse = {
  data: ActivityTrailResponseDataEntry[]
}

import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulGatsbyImage } from '../../types/images';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { richTextRenderer } from '../richText/richText';
import MainHeader from '../../components/header/PageHeader';
import { ChildMeta } from '../meta';
import { PageProps } from './types';
import { ContentfulAsset } from '../../../graphql-types';
import Grid from '../../components/common/layout/Grid';
import Section from '../../components/section/Section';
import useContentfulTransformedCardGridData from '../../hooks/useContentfulTransformedCardGridData';
import { CardType } from '../../components/common/card/Card';
import CardGrid from '../../components/common/cardgrid/CardGrid';
import CardGridHeader from '../../components/common/cardgrid/CardGridHeader';
import TextContent from '../../components/common/textContent/TextContent';
import { Typography } from '../../components/common/typography/Typography.styled';
import { localize } from '../../localization/i18n';
import BreadCrumbs from '@Levi/components/navigation/breadcrumbs/BreadCrumbs';

type BlogPostPageTypes = {
  title: string;
  image: ContentfulGatsbyImage;
  metaImage: ContentfulAsset;
  ingress: { ingress: string };
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  tags: string[];
};

export type BlogPostCardFields = {
  id: string;
  title: string;
  image: ContentfulGatsbyImage;
};

type RelatedPostsType = {
  edges: { node: BlogPostCardFields }[];
};

type BlogPostPageProps = PageProps & {
  data: {
    contentfulBlogPost: BlogPostPageTypes;
    relatedPosts: RelatedPostsType;
  };
};

const ContentfulBlogPostPage: React.FC<BlogPostPageProps> = ({
  data,
  pageContext,
}) => {
  const { title, image, metaImage, ingress, body } = data.contentfulBlogPost;

  const relatedPostCards = useContentfulTransformedCardGridData({
    cards: data.relatedPosts?.edges,
    type: CardType.BlogPost,
  });

  const mainContent = React.useMemo(
    () => (body ? richTextRenderer({ data: body }) : undefined),
    [body],
  );
  const meta = {
    lang: pageContext.locale,
    description: ingress?.ingress || '',
    image: metaImage,
    title: title,
    hiddenFromSearchEngines: false,
    //structuredMetaData,
  };


  return (
    <>
      <ChildMeta {...meta} />
      <main>
        <MainHeader image={image} />
        {pageContext.breadcrumbs.length > 1 && <BreadCrumbs links={pageContext.breadcrumbs} />}
        <Section>
          <Grid>
            <TextContent title={title}>
              {ingress?.ingress && (
                <Typography.BodyIngres>{ingress.ingress}</Typography.BodyIngres>
              )}
              {mainContent}
            </TextContent>
          </Grid>
        </Section>
        {relatedPostCards?.length > 0 && (
          <Section>
            <Grid>
              {' '}
              <CardGridHeader
                title={localize('blogPosts.similarArticlesTitle')}
                centered
              />
              <CardGrid cards={relatedPostCards} />
            </Grid>
          </Section>
        )}
      </main>
    </>
  );
};

export default ContentfulBlogPostPage;

export const contentfulBlogPostQuery = graphql`
  query BlogPostById($id: String!, $locale: String!, $tags: [String]!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      image {
        ...contentfulHeroImage
      }
      ingress {
        ingress
      }
      metaImage: image {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: [JPG]
          placeholder: NONE
          resizingBehavior: FILL
        )
      }
      body {
        ...contentfulBlogPostBodyRichTextFields
      }
      tags
      analyticsTags
    }
    # Get related posts where
    # is not current post
    # is same locale as current post
    # has same tags as current post
    relatedPosts: allContentfulBlogPost(
      limit: 6
      filter: {
        id: { ne: $id }
        node_locale: { eq: $locale }
        tags: { in: $tags }
      }
    ) {
      edges {
        node {
          ...blogPostCardFields
        }
      }
    }
  }

  fragment blogPostCardFields on ContentfulBlogPost {
    id
    title
    node_locale
    date
    description: ingress {
      ingress
    }
    internal {
      type
    }
    image {
      ...contentfulCardImage
    }
    hideFromLocalization
  }
`;

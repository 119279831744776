import React from 'react';
import { addDays, format, startOfToday } from 'date-fns';
import AccommodationSearchBar from './AccommodationSearchBar';
import { Guests } from '../common/list-filters/FilterGuestsForm';
import { DateRange, isValidDateRange } from '@Levi/utils/date-utils';
import { encodeAsURIParams } from '@Levi/contentful/templates/utils';
import { Link, navigate } from 'gatsby';
import { ExternalPageLink, InternalPageLink } from '@Levi/contentful/callToActions/types';
import useContentfulCallToAction, { LinkProps } from '@Levi/hooks/useContentfulCallToAction';


const WinresAccommodationSearchBar: React.FC<{ destination: InternalPageLink | ExternalPageLink }> = ({
  destination
}): JSX.Element => {

  const [guests, setGuests] = React.useState<Guests>({
    adults: 2,
    children: 0,
    infants: 0
  });

  const startDate = addDays(startOfToday(), 3);
  const [dates, setDates] = React.useState<DateRange | undefined>({
    start: startDate,
    end: addDays(startDate, 2)
  });

  const buildParams = React.useCallback(() => {
    const dateParams =
      dates && isValidDateRange(dates)
        ? [`startDate=${format(dates.start, 'yyyy-MM-dd')}`, `endDate=${format(dates.end, 'yyyy-MM-dd')}`]
        : [];

    const guestParams =
      guests.adults > 0 || guests.children > 0
        ? [`numAdults=${encodeAsURIParams(guests.adults)}`, `numChildren=${guests.children}`, `numInfants=${guests.infants}`]
        : [];

    const paramsString = [...dateParams, ...guestParams]
      .filter((params) => !!params)
      .join('&');
    if (paramsString) {
      return `#!/?${paramsString}`;
    }
    return '';
  }, [dates, guests]);



  const destinationLink = useContentfulCallToAction({
    destination: destination,
    target: 'self'
  }) as LinkProps<typeof Link>;

  const onSubmitSearch = React.useCallback(() => {
    if (destinationLink) {
      navigate(destinationLink.to + buildParams());
    }
  }, [destinationLink, buildParams]);

  return (<AccommodationSearchBar
    key="accommodation-search-bar"
    onGuestsChange={setGuests}
    guests={guests}
    dates={dates}
    onDatesChange={setDates}
    onSubmit={onSubmitSearch}
  />);
};

export default WinresAccommodationSearchBar;

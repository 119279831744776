import { ApiPath } from '..';
import { WeatherStationType } from '../types/types';
import { ApiDataParser } from './ApiDataParser';

export const weatherDataParser: ApiDataParser<ApiPath.Weather> = (data) => {
  const leviPeak = data.find(
    (location: WeatherStationType) =>
      //location.id === 2000110 && location.name === 'GU101',
    location.id === "184115730462"
  );
  const leviCentre = data.find(
    //(location: WeatherStationType) => location.id === 1,
    //(location: WeatherStationType) => location.id === "184115727932",
    (location: WeatherStationType) => location.id === "185214938918",
  );

  return { leviPeak, leviCentre };
};

import React, { ReactNode } from 'react';
import { graphql } from 'gatsby';
import ContentfulCallToAction, {
  ContentfulCallToActionProps,
} from '../callToActions/contentfulCallToAction';
import MainHeader from '../../components/header/PageHeader';
import { ContentfulGatsbyImage } from '../../types/images';

export type ContentfulHeroProps = {
  title?: string;
  subtitle?: string;
  heroImage: ContentfulGatsbyImage;
  imageCredits?: string;
  callToAction?: ContentfulCallToActionProps;
  layout: string;
  children?: ReactNode;
};

const ContentfulHero: React.FC<ContentfulHeroProps> = ({
  title,
  subtitle,
  heroImage,
  imageCredits,
  callToAction,
  layout,
  children,
}) => {

  
  const callToActionProps = { ...callToAction, type: callToAction?.type || 'button' };
  return (
    <MainHeader
      image={heroImage}
      title={title}
      subtitle={subtitle}
      imageCredits={imageCredits}
      simpleLayout={layout === 'Simple'}
    >
      {(children || callToAction) && (
        <>
          {children}
          {callToAction && <ContentfulCallToAction {...callToActionProps} />}
        </>
      )}
    </MainHeader>
  );
};

export default ContentfulHero;

export const contentfulHeroQuery = graphql`
  fragment contentfulHeroFields on ContentfulHero {
    title
    subtitle
    callToAction {
      ...contentfulCallToActionFields
    }
    layout
    imageCredits
    heroImage: backgroundImage {
      ...contentfulHeroImage
    }
    # TODO: add rest of the fields
  }
`;

import React from 'react';
import { localize } from '../../../localization/i18n';
import useDropdownToggle from '../dropdown/useDropdownToggle';
import NumericInput from '../input/NumericInput';
import { Typography } from '../typography/Typography.styled';
import Styled from './FilterGuestsForm.styled';

export type Guests = {
  [key: string]: number;
};

export interface FilterGuestsFormProps {
  onChange: (guests: Guests) => void;
  guests: Guests;
}

const FilterGuestsForm = ({
  onChange,
  guests,
}: FilterGuestsFormProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { isOpen, onDropdownClick } = useDropdownToggle({ ref });

  const addGuest = (key: string) => {
    onChange({
      ...guests,
      [key]: guests[key] + 1,
    });
  };

  const takeGuest = (key: string) => {
    onChange({
      ...guests,
      [key]: guests[key] > 0 ? guests[key] - 1 : guests[key],
    });
  };

  return (
    <Styled.FilterGuestsForm ref={ref}>
      <Styled.ToggleButton onClick={onDropdownClick}>
        <Typography.SubLink>
          {localize('filters.guests.buttonLabel')}
        </Typography.SubLink>
        <Typography.BodySmall>
          {guests.adults + guests.children + guests.infants > 0
            ? `${guests.adults + guests.children + guests.infants} ${localize(
                'filters.guests.guestCountSuffix',
              )}`
            : localize('filters.guests.buttonHelper')}
        </Typography.BodySmall>
      </Styled.ToggleButton>
      <Styled.FilterGuestsFormContainer isOpen={isOpen}>
        <Styled.FieldSet>
          <Typography.LabelSmall>
            {localize('filters.guests.adults')}
          </Typography.LabelSmall>
          <NumericInput
            decrease={() => takeGuest('adults')}
            increase={() => addGuest('adults')}
            value={guests.adults}
            increaseLabel={localize('filters.guests.increaseLabel')}
            decreaseLabel={localize('filters.guests.decreaseLabel')}
          />
        </Styled.FieldSet>
        <Styled.FieldSet>
          <Typography.LabelSmall>
            {localize('filters.guests.children')}
          </Typography.LabelSmall>
          <NumericInput
            decrease={() => takeGuest('children')}
            increase={() => addGuest('children')}
            increaseLabel={localize('filters.guests.increaseLabel')}
            decreaseLabel={localize('filters.guests.decreaseLabel')}
            value={guests.children}
          />
        </Styled.FieldSet>
        <Styled.FieldSet>
          <Typography.LabelSmall>
            {localize('filters.guests.infants')}
          </Typography.LabelSmall>
          <NumericInput
            decrease={() => takeGuest('infants')}
            increase={() => addGuest('infants')}
            increaseLabel={localize('filters.guests.increaseLabel')}
            decreaseLabel={localize('filters.guests.decreaseLabel')}
            value={guests.infants}
          />
        </Styled.FieldSet>
      </Styled.FilterGuestsFormContainer>
    </Styled.FilterGuestsForm>
  );
};

export default FilterGuestsForm;

import React from 'react';
import { Icons } from '../../../types/icon-types';
import Icon, { IconSize } from '../../common/icon/Icon';
import { Typography } from '../../common/typography/Typography.styled';
import Styled from './Weather.styled';
import WeatherDropdown, { DetailedWeatherContent } from './WeatherDropdown';
import { useSkiResortDataByApiPath } from '../../../hooks/useSkiResortDataByApiPath';
import { useDropdownCalculations } from '../main/desktop/DesktopNavigation';
import { ApiPath } from '../../../network/skiresort-api';
import { NavLinkProps } from '../main/MainNavigationPropTypes';
import { SkiResortWeatherType } from '../../../context/types';
import NavigationSheet from '../main/mobile/NavigationSheet';
import { WeatherForecastType } from '../../../network/skiresort-api/types/types';
import useNavigationSheet from '../../../hooks/useNavigationSheet';
import { localize } from '../../../localization/i18n';
import Spinner from '../../common/loader/Spinner';

const Weather = ({
  weatherCamerasLink,
  simple = false,
}: {
  weatherCamerasLink?: NavLinkProps;
  simple?: boolean;
}): JSX.Element | null => {
  const weather = useSkiResortDataByApiPath(ApiPath.Weather);
  const weatherForecast = useSkiResortDataByApiPath(ApiPath.WeatherForecast);

  if (simple) {
    return (
      <WeatherSheet
        weather={weather}
        weatherForecast={weatherForecast}
        weatherCamerasLink={weatherCamerasLink}
      />
    );
  }

  return (
    <DetailedWeather
      weather={weather}
      weatherForecast={weatherForecast}
      weatherCamerasLink={weatherCamerasLink}
    />
  );
};

const SimpleWeather = ({
  weather,
  onClick,
}: {
  weather?: SkiResortWeatherType;
  onClick?: () => void;
}) => {
  return (
    <Styled.WeatherSummary onClick={onClick}>
      <Styled.WeatherSpinner show={!weather}>
        <Styled.WeatherStationSummary>
          <Spinner />
        </Styled.WeatherStationSummary>
      </Styled.WeatherSpinner>
      <Styled.WeatherSummaryAnimContainer show={!!weather}>
        {weather?.leviPeak?.weather?.temperature != null && (
          <Styled.WeatherStationSummary>
            <Icon type={Icons.WeatherMountain} />
            <Typography.NavigationLink>
              {weather.leviPeak.weather.temperature.toFixed(1)}°C
            </Typography.NavigationLink>
          </Styled.WeatherStationSummary>
        )}
        {weather?.leviCentre?.weather?.temperature != null && (
          <Styled.WeatherStationSummary>
            <Icon type={Icons.WeatherValley} />
            <Typography.NavigationLink>
              {weather.leviCentre.weather.temperature.toFixed(1)}°C
            </Typography.NavigationLink>
          </Styled.WeatherStationSummary>
        )}
      </Styled.WeatherSummaryAnimContainer>
    </Styled.WeatherSummary>
  );
};

const DetailedWeather = ({
  weather,
  weatherForecast,
  weatherCamerasLink,
}: {
  weather?: SkiResortWeatherType;
  weatherForecast?: WeatherForecastType;
  weatherCamerasLink?: NavLinkProps;
}): JSX.Element | null => {
  const ref = React.useRef<HTMLLIElement>(null);
  const { recalculate } = useDropdownCalculations({ ref });

  React.useEffect(() => recalculate(), [weather, recalculate]);

  return (
    <Styled.Weather ref={ref} hasSubPages={!!weather && !!weatherForecast}>
      <SimpleWeather weather={weather} />
      {!!weather && !!weatherForecast && (
        <WeatherDropdown
          weather={weather}
          weatherForecast={weatherForecast}
          weatherCamerasLink={weatherCamerasLink}
        />
      )}
    </Styled.Weather>
  );
};

const WeatherSheet = ({
  weather,
  weatherForecast,
  weatherCamerasLink,
}: {
  weather?: SkiResortWeatherType;
  weatherForecast?: WeatherForecastType;
  weatherCamerasLink?: NavLinkProps;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const {
    actions: { open, close },
  } = useNavigationSheet(ref);

  return (
    <>
      <SimpleWeather
        weather={weather}
        onClick={!!weather && !!weatherForecast ? open : undefined}
      />
      <NavigationSheet
        ref={ref}
        className="flex"
        headerContent={
          <>
            <Icon
              type={Icons.Close}
              size={IconSize.Size32}
              style={{ visibility: 'hidden' }}
            />
            <Typography.SubHeadingLarge>
              {localize('weather.title')}
            </Typography.SubHeadingLarge>
            <Icon type={Icons.Close} onClick={close} size={IconSize.Size32} />
          </>
        }
      >
        {weather && weatherForecast && (
          <DetailedWeatherContent
            weather={weather}
            weatherForecast={weatherForecast}
            weatherCamerasLink={weatherCamerasLink}
          />
        )}
      </NavigationSheet>
    </>
  );
};

export default Weather;

import { ApiPath } from '..';
import { SummerActivityApiMap } from '../../../context/types';
import { LeviTodayFields } from '../../../hooks/useLeviTodayData';
import { LeviTodaySummaryField, LeviTodaySummerActivitySummaryField, SummerActivityFieldName } from '../types/types';
import { ApiDataParser } from './ApiDataParser';

const isSummaryField = (field: any) =>
  field != null &&
  typeof field === 'object' &&
  typeof field.Open === 'number' &&
  typeof field.Total === 'number';

const formatSummaryField = (field: any) =>
  isSummaryField(field) ? `${field.Open}/${field.Total}` : "";

export const todayDataParser: ApiDataParser<ApiPath.LeviToday> = (fields) => {
  let data: LeviTodayFields = [];

  data.push({fieldName: "Lifts", value: formatSummaryField(fields["lifts"])});
  data.push({fieldName: "Slopes", value: formatSummaryField(fields["slopes"])});
  data.push({fieldName: "Snow parks", value: formatSummaryField(fields["snowParks"])});
  data.push({fieldName: "Snowmaking", value: `${fields["slopes"]["SnowPreparations"]}`});
  data.push({fieldName: "Ski tracks", value: fields["skiTracks"]});
  data.push({fieldName: "Snowmobile tracks", value: fields["snowMobileTracks"]});
  data.push({fieldName: "Trekking routes", value: fields["trekkingRoutes"]});
  
  data.push({fieldName: "Snow depth", value: fields["snowDepth"]});
  data.push({fieldName: "Bike trails", value: formatSummaryField(fields["bicycleTrails"])});

  

  handleSummerActivity(fields["summerActivities"], "seikkailuratoja_avoinna", data);
  handleSummerActivity(fields["summerActivities"], "zipline_avoinna", data);
  handleSummerActivity(fields["summerActivities"], "basejump_avoinna", data);
  handleSummerActivity(fields["summerActivities"], "kelkkarata_avoinna", data);
  handleSummerActivity(fields["summerActivities"], "liikennepuisto", data);
  handleSummerActivity(fields["summerActivities"], "trampoliini", data);


  data.push({fieldName: "Slope restaurants", value: formatSummaryField(fields["slopeRestaurants"])});
  data.push({fieldName: "Weather", value: `${fields["weather"].toFixed(1)} °C`});
  data.push({fieldName: "Slope cameras", value: formatSummaryField(fields["slopeCameras"])});

  return data;
};

/** Check if activity data is present. If so, push it to data array */
function handleSummerActivity(activityList: LeviTodaySummerActivitySummaryField[], name: SummerActivityFieldName, data: LeviTodayFields){
    const value = activityList.find(a=>a.Name == name);
    data.push({
      fieldName : SummerActivityApiMap[name],
      value     : formatSummaryField(value),
    })
}

import React from 'react';
import { graphql } from 'gatsby';


import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../containers/contentfulSectionContainer';
import { PageProps } from './types';




const ContentfulAccommodationPage: React.FC<PageProps> = ({ data: {
  contentfulPage: { sections }
} }) => {


  return (
    <main>
      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}
    </main>
  );
};


export default ContentfulAccommodationPage;


export const contentfulPageQuery = graphql`
  query AccommodationPageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
      analyticsTags
    }  
  }
`;
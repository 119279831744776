import React, { ReactNode } from 'react';
import ContentfulNavigation from './navigation/contentfulNavigation';
import PageMeta, { ContentfulPageMetaFields } from './meta';
import { PageContextTypes } from './templates/types';
import ContentfulFooter from './footer/contentfulFooter';
import { Theme } from '../styles/themes';
import {
  createHeaderStore,
  HeaderStateProvider,
} from '../components/header/headerStore';
import ContentfulHero, { ContentfulHeroProps } from './hero/contentfulHero';
import InfoBanner from './infoBanner';
import BackNavigation from '../components/navigation/back-navigation/BackNavigation';
import { HistoryLocation } from '@reach/router';
import BreadCrumbs from '../components/navigation/breadcrumbs/BreadCrumbs';
import useStructuredDataForPage from '../hooks/useStructuredDataForPage';
import { HeroCarousel } from '../components/header/HeroCarousel';

type LayoutProps = {
  children: ReactNode;
  pageProps: PageContextTypes;
  meta: ContentfulPageMetaFields;
  heroCarousel?: ContentfulHeroProps[];
  invertedNavigationTheme?: boolean;
  location: HistoryLocation;
};

const Layout: React.FC<LayoutProps> = ({
  pageProps: { breadcrumbs = [], ...pageProps },
  meta,
  heroCarousel,
  invertedNavigationTheme = false,
  location,
  children,
}) => {
  const structuredMetaData = useStructuredDataForPage({
    pathname: location.pathname,
    origin: location.origin,
  });

  const pageMeta = {
    ...meta,
    localizedLinks: pageProps?.localizedLinks ?? [],
    structuredMetaData,
  };
  return (
    <HeaderStateProvider createStore={createHeaderStore}>
      <PageMeta {...pageMeta} />
      <ContentfulNavigation
        {...pageProps}
        theme={invertedNavigationTheme ? Theme.PrimaryInverse : Theme.Default}
      />

      {heroCarousel ? (
        <>
          <HeroCarousel heroCarousel={heroCarousel} />
          <InfoBanner />
          {breadcrumbs.length > 1 && <BreadCrumbs links={breadcrumbs} />}
        </>
      ) : (
        <>
          {/*<BackNavigation location={location} breadcrumbs={breadcrumbs} />*/}
          {/*breadcrumbs.length > 1 && <BreadCrumbs links={breadcrumbs} />*/}
          <InfoBanner />
        </>
      )}
      {children}

      <ContentfulFooter />
    </HeaderStateProvider>
  );
};

export default Layout;

import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulMarkdownTypes, PageProps } from '../types';
import { ContentfulGatsbyImage } from '../../../types/images';
import { ContentfulAsset } from '../../../../graphql-types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Section from '../../../components/section/Section';
import TextContent from '../../../components/common/textContent/TextContent';
import {
  SubpageGrid,
  SubpageGridElement,
} from '../../../components/common/layout/Grid.styled';
import ContactDetails from '../../../components/common/contactDetails/ContactDetails';
import MainHeader from '../../../components/header/PageHeader';
import Gallery from '../../../components/gallery/Gallery';
import MapContainer from '../../../components/common/map/MapContainer';
import { ServiceFields } from '../serviceListPages/contentfulSearchServicesPage';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { ChildMeta } from '../../meta';
import Grid from '../../../components/common/layout/Grid';
import useHtmlAst from '../../../hooks/useHtmlAst';
import BreadCrumbs from '@Levi/components/navigation/breadcrumbs/BreadCrumbs';

type AllServiceFields = ServiceFields & {
  body?: ContentfulMarkdownTypes;
  images: ContentfulAsset[];
  galleryImages: ContentfulGatsbyImage[];
  logo?: ContentfulGatsbyImage;
};

type ServicePageProps = PageProps & {
  data: {
    contentfulService: AllServiceFields;
  };
};

const ContentfulService: React.FC<ServicePageProps> = ({
  data,
  pageContext,
}) => {
  const {
    name,
    ingress,
    body,
    location: serviceLocation,
    //categories,
    openingHours,
    images,
    galleryImages,
    contact,
    logo,
  } = data.contentfulService;

  const meta = {
    lang: pageContext.locale,
    description: ingress,
    image: images && images[0],
    title: name,
    hiddenFromSearchEngines: false,
  };

  const { isBelowMd } = useBreakpoints();
  const bodyText = useHtmlAst(body?.childMarkdownRemark?.htmlAst);

  return (
    <>
      <ChildMeta {...meta} />
      <main>
        {galleryImages && <MainHeader image={galleryImages[0]} />}
        {pageContext.breadcrumbs.length > 1 && <BreadCrumbs links={pageContext.breadcrumbs} />}
        <Section subPage>
          <SubpageGrid>
            <SubpageGridElement padded>
              {isBelowMd && galleryImages && <Gallery items={galleryImages} />}
              <TextContent title={name} leftAligned>
                <Typography.BodyIngres>{ingress}</Typography.BodyIngres>
                {bodyText && (
                  <Typography.BodyLarge className="contentfulMarkdown" as="div">
                    {bodyText}
                  </Typography.BodyLarge>
                )}
              </TextContent>
            </SubpageGridElement>
            <SubpageGridElement column="aside" padded>
              {!isBelowMd && galleryImages && <Gallery items={galleryImages} />}
              {contact && (
                <ContactDetails
                  logo={logo}
                  openingHours={openingHours}
                  {...contact}
                />
              )}
            </SubpageGridElement>
          </SubpageGrid>
        </Section>
        {serviceLocation && (
          <Section>
            <Grid>
              <MapContainer
                address={contact?.address || ''}
                location={serviceLocation}
              />
            </Grid>
          </Section>
        )}
      </main>
    </>
  );
};

export default ContentfulService;

export const contentfulPageQuery = graphql`
  query ServiceById($id: String!) {
    contentfulService(id: { eq: $id }) {
      ...contentfulBaseServiceFields
      body {
        childMarkdownRemark {
          htmlAst
        }
      }
      images {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: [JPG]
          placeholder: NONE
          resizingBehavior: FILL
        )
      }
      galleryImages: images {
        image: gatsbyImageData(layout: FULL_WIDTH)
        description
        title
        id
      }
      contact {
        ...contentfulCompanyFields
      }
      logo {
        image: gatsbyImageData(width: 300)
        description
        title
      }
    }
  }
  fragment contentfulBaseServiceFields on ContentfulService {
    id
    contentful_id
    name
    categories
    contact {
      address
    }
    ingress
    location {
      lat
      lon
    }
    hideFromLocalization
    openingHours {
      exceptions
      hidden

      openingHours {
        monday {
          to
          from
          closed
        }
        tuesday {
          closed
          from
          to
        }
        wednesday {
          to
          from
          closed
        }
        thursday {
          closed
          from
          to
        }
        friday {
          to
          from
          closed
        }
        saturday {
          closed
          from
          to
        }
        sunday {
          closed
          from
          to
        }
      }
    }
  }
`;

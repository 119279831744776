import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { ContentfulAsset } from '../../graphql-types';
import { LocalizedLink, LocaleType } from './templates/types';
import { ContentfulGatsbyImage } from '../types/images';
import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';
import { isIGatsbyImageData } from '../hooks/useContentfulGatsbyImage';

export type MetaImage = {
  childImageSharp: ContentfulGatsbyImage;
};

export type ContentfulPageMetaFields = {
  title: string;
  description: string;
  image?: ContentfulAsset | string;
  lang: LocaleType;
  altLang?: string;
  hiddenFromSearchEngines: boolean;
  localizedLinks?: LocalizedLink[];
};

type OpeningHoursSpecification = {
  '@type': string;
  dayOfWeek?: string;
  opens?: string;
  closes?: string;
};

type StructuredDataAddress = {
  '@type': string;
  addressLocality?: string;
  addressCountry?: string;
  streetAddress?: string;
};

type StructuredDataLocation = {
  '@type': string;
  address: StructuredDataAddress;
};

export type StructuredMetaData = {
  '@context': string;
  '@type': string;
  image?: string | string[];
  address?: string | StructuredDataAddress;
  location?: StructuredDataLocation;
  telephone?: string;
  email?: string;
  url?: string;
  name: string;
  openingHoursSpecification?: OpeningHoursSpecification[];
  startDate?: string;
  endDate?: string;
  description?: string;
};

export const resolveImageUrl = (image?: ContentfulAsset | string) => {
  const url = image
    ? typeof image === 'string'
      ? `${process.env.GATSBY_HOST_URL}${image}`
      : isIGatsbyImageData(image)
      ? `https:${getSrc(
          (image as ContentfulAsset).gatsbyImageData as IGatsbyImageData,
        )}`
      : `https:${image.file?.url}`
    : undefined;
  return url ? decodeURI(url) : undefined;
};

/**
 * (Optional) Custom page specific meta that overrides PageMeta tags
 */
export const ChildMeta = ({
  title,
  description,
  image,
  lang,
  altLang,
  hiddenFromSearchEngines,
  structuredMetaData,
  children,
}: ContentfulPageMetaFields & {
  structuredMetaData?: StructuredMetaData;
  children?: React.ReactNode;
}) => {
  const pageLang = altLang || lang;
  return (
    <Helmet>
      {lang && <html lang={pageLang} />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {image && <meta property="og:image" content={resolveImageUrl(image)} />}
      {title && <meta name="twitter:title" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      <meta
        name="robots"
        content={hiddenFromSearchEngines ? 'noindex' : 'index'}
      />
      {structuredMetaData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredMetaData)}
        </script>
      )}
      {children}
    </Helmet>
  );
};

/**
 * Note that most of the static head tags have been moved to html.js
 */

const PageMeta: React.FC<
  ContentfulPageMetaFields & { structuredMetaData?: StructuredMetaData }
> = ({
  title,
  description,
  image,
  lang,
  altLang,
  hiddenFromSearchEngines,
  structuredMetaData,
  localizedLinks = []
}) => {
  const pageLang = altLang || lang;
  return (
    <Helmet>
      <html lang={pageLang || 'fi'} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {image && <meta property="og:image" content={resolveImageUrl(image)} />}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        name="robots"
        content={hiddenFromSearchEngines ? 'noindex' : 'index'}
      />
      {structuredMetaData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredMetaData)}
        </script>
      )}
       {localizedLinks.map(({rel, hreflang, href }) => (
        <link key={href} rel={rel} {...(hreflang ? { hrefLang: hreflang } : {})} href={href} />
      ))}
    </Helmet>
  );
};

export default PageMeta;

export const contentfulPageMeta = graphql`
  fragment contentfulPageMetaFields on ContentfulMeta {
    title
    description
    lang: node_locale
    altLang: language
    image {
      gatsbyImageData(
        layout: FIXED
        width: 1200
        height: 630
        formats: [JPG]
        placeholder: NONE
        resizingBehavior: FILL
      )
    }
    hiddenFromSearchEngines
  }
`;

import { navigate } from 'gatsby';
import React from 'react';
import ContentfulCallToAction from '../../../contentful/callToActions/contentfulCallToAction';
import {
  ContentfulCallToActionTypes,
  ExternalPageLink,
  InternalPageLink,
} from '../../../contentful/callToActions/types';
import useContentfulCallToAction, {
  isInternalCTA,
} from '../../../hooks/useContentfulCallToAction';
import useNewsletterSubmitForm, {
  NewsletterSubscriptionPayload,
} from '../../../hooks/useNewsletterSubmitForm';
import { localize } from '../../../localization/i18n';
import useLocale from '../../../localization/useLocale';
import Button from '../button/Button';
import Input from '../input/Input';
import { Typography } from '../typography/Typography.styled';
import Styled from './SubscribeNewsletterForm.styled';

const initialSubsriptions: NewsletterSubscriptionPayload = {
  email: '',
  locale: 'fi',
  visitLevi: {
    selected: false,
    targetList: [],
  },
  leviSkiResort: {
    selected: false,
    targetList: [],
  },
};

export interface SubscribeNewsletterFormProps {
  title: string;
  redirectDestination: ExternalPageLink | InternalPageLink;
  privacyPolicyPage: ExternalPageLink | InternalPageLink;
  visitLeviTargetList: string[];
  skiResortTargetList: string[];
}

const SubscribeNewsletterForm = ({
  title,
  redirectDestination,
  privacyPolicyPage,
  visitLeviTargetList,
  skiResortTargetList,
}: SubscribeNewsletterFormProps): JSX.Element => {
  const locale = useLocale();
  const [formData, setFormData] = React.useState<NewsletterSubscriptionPayload>(
    {
      ...initialSubsriptions,
      locale,
      visitLevi: {
        ...initialSubsriptions.visitLevi,
        targetList: visitLeviTargetList,
      },
      leviSkiResort: {
        ...initialSubsriptions.leviSkiResort,
        targetList: skiResortTargetList,
      },
    },
  );

  const [privacyPolicyConsent, setPrivacyPolicyConsent] =
    React.useState<boolean>(false);
  const [showConsentNotice, setShowConsentNotice] =
    React.useState<boolean>(false);

  const [onSubmit, status, data, resetStatus] = useNewsletterSubmitForm();

  const handleSubmit = () => {
    if (!privacyPolicyConsent) {
      setShowConsentNotice(true);
      return;
    }

    dataLayer.push({ 'event': 'newsletter_signup' });

    onSubmit(formData);
  };

  const privacyPolicyCta: ContentfulCallToActionTypes = {
    title: localize('newsletter.consentLinkText'),
    target: "tab",
    destination: {
      pageId: privacyPolicyPage.pageId || '',
    },
  };

  const redirectLink = useContentfulCallToAction({
    title: '',
    target: "tab",
    destination: {
      pageId: redirectDestination.pageId || '',
    },
  });

  // Post fetched effect
  React.useEffect(()=>{
    if(status !== 'fetched') return;

    // Track that lead was created
    window.fbq?.("track", "Lead");
    
    // Reset form after 3sec
    const handle = setTimeout(()=>{
      resetStatus();
      setFormData(initialSubsriptions);
      setPrivacyPolicyConsent(false);
    }, 4000);

    return ()=>clearTimeout(handle);
  }, [status]);

  React.useEffect(() => {
    if (
      status === 'fetched' &&
      !data.error &&
      redirectLink &&
      isInternalCTA(redirectLink)
    ) {
      navigate(redirectLink.to);
    }
    if (
      status === 'fetched' &&
      !data.error &&
      redirectDestination.externalUrl
    ) {
      window.location.replace(redirectDestination.externalUrl);
    }

    if (privacyPolicyConsent && showConsentNotice) {
      setShowConsentNotice(false);
    }
  }, [
    status,
    data,
    redirectLink,
    privacyPolicyConsent,
    showConsentNotice,
    redirectDestination,
  ]);

  return (
    <Styled.NewsLetterContainer>
      <Styled.NewsLetterInner>
        <Styled.SubscribeNewsletterFormStyled>
          <Typography.HeadingSmall>{title}</Typography.HeadingSmall>
          {status === 'fetched' && (
            <Styled.SuccessNoticeContainer>
              <Typography.BodyLarge>{localize('newsletter.thank-you')}</Typography.BodyLarge>
            </Styled.SuccessNoticeContainer>
          )}
          {data.error && status === 'fail' && (
            <Styled.ErrorNoticeContainer>
              <Typography.BodyLarge>{data.error}</Typography.BodyLarge>
            </Styled.ErrorNoticeContainer>
          )}
          <Styled.FieldContainer>
            <Input
              value={formData.email}
              label={localize('newsletter.email')}
              placeholder={localize('newsletter.placeholder')}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  email: e.target.value,
                })
              }
              disabled={status != "idle"}
            />
          </Styled.FieldContainer>
          <Styled.FieldContainer>
            <Typography.LabelSmall>
              {localize('newsletter.selectLabel')}
            </Typography.LabelSmall>
          </Styled.FieldContainer>
          <Styled.FieldContainer>
            <Styled.Label>
              <input
                type="checkbox"
                name="visitLevi"
                onChange={() =>
                  setFormData({
                    ...formData,
                    visitLevi: {
                      ...formData.visitLevi,
                      selected: !formData.visitLevi.selected,
                    },
                  })
                }
                checked={formData.visitLevi.selected}
                disabled={status != "idle"}
              />
              {localize('newsletter.visitLeviLabel')}
            </Styled.Label>
          </Styled.FieldContainer>
          <Styled.FieldContainer>
            <Styled.Label>
              <input
                type="checkbox"
                name="leviSkiResort"
                onChange={() =>
                  setFormData({
                    ...formData,
                    leviSkiResort: {
                      ...formData.leviSkiResort,
                      selected: !formData.leviSkiResort.selected,
                    },
                  })
                }
                checked={formData.leviSkiResort.selected}
                disabled={status != "idle"}
              />
              {localize('newsletter.leviSkiResortLabel')}
            </Styled.Label>
          </Styled.FieldContainer>
          <Styled.FieldContainer>
            <Button
              disabled={
                status != "idle" ||
                formData.email.length === 0 ||
                (!formData.visitLevi.selected &&
                  !formData.leviSkiResort.selected)
              }
              onClick={handleSubmit}
            >
              {localize('newsletter.subscribe')}
            </Button>
          </Styled.FieldContainer>
          <Styled.FieldContainer>
            <Styled.Label>
              <input
                type="checkbox"
                name="privacyPolicy"
                onChange={() => setPrivacyPolicyConsent(!privacyPolicyConsent)}
                checked={privacyPolicyConsent}
                disabled={status != "idle"}
              />
              {localize('newsletter.consentLabel')}{' '}
              <ContentfulCallToAction
                {...privacyPolicyCta}
                type="inline-link"
              />
              {'.'}
            </Styled.Label>
          </Styled.FieldContainer>
          {showConsentNotice && (
            <Styled.ErrorNoticeContainer>
              <Typography.BodySmall>
                {localize('newsletter.consentNotice')}
              </Typography.BodySmall>
            </Styled.ErrorNoticeContainer>
          )}
        </Styled.SubscribeNewsletterFormStyled>
      </Styled.NewsLetterInner>
    </Styled.NewsLetterContainer>
  );
};

export default SubscribeNewsletterForm;

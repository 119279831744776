import React from 'react';
import { graphql } from 'gatsby';
import InfoGrid from '../../components/common/infogrid/InfoGrid';
import { LeviTodayFieldKeys } from '../../context/types';
import useLeviTodayData from '../../hooks/useLeviTodayData';
import { ContentfulCallToActionTypes } from '../callToActions/types';

export type ContentfulLeviTodayTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  content: LeviTodayFieldKeys[];
  // optional callToActions for each LeviTodayFields
  lifts?: ContentfulCallToActionTypes;
  bikeTrails?: ContentfulCallToActionTypes;
  slopeCameras?: ContentfulCallToActionTypes;
  skiTracks?: ContentfulCallToActionTypes;
  slopes?: ContentfulCallToActionTypes;
  snowDepth?: ContentfulCallToActionTypes;
  snowmobileTracks?: ContentfulCallToActionTypes;
  slopeRestaurants?: ContentfulCallToActionTypes;
  trekkingRoutes?: ContentfulCallToActionTypes;
  weather?: ContentfulCallToActionTypes;
  snowmaking?: ContentfulCallToActionTypes;
  snowParks?: ContentfulCallToActionTypes
};

const ContentfulLeviToday = ({
  title,
  content,
  lifts,
  bikeTrails,
  slopeCameras,
  skiTracks,
  slopes,
  snowDepth,
  snowmobileTracks,
  slopeRestaurants,
  trekkingRoutes,
  weather,
  snowmaking,
  snowParks
}: ContentfulLeviTodayTypes): JSX.Element => {
  const callToActions = {
    Lifts: lifts,
    'Bike trails': bikeTrails,
    'Slope cameras': slopeCameras,
    'Ski tracks': skiTracks,
    Slopes: slopes,
    'Snow depth': snowDepth,
    'Snowmobile tracks': snowmobileTracks,
    'Slope restaurants': slopeRestaurants,
    'Trekking routes': trekkingRoutes,
    Weather: weather,
    Snowmaking: snowmaking,
    'Snow parks': snowParks
  };

  const data = useLeviTodayData(content, callToActions);

  return <InfoGrid title={title} data={data} />;
};

export default ContentfulLeviToday;

export const contentfulLeviTodayQuery = graphql`
  fragment contentfulLeviTodayFields on ContentfulLeviToday {
    id
    internal {
      type
    }
    title
    content
    lifts {
      ...contentfulCallToActionFields
    }
    slopes {
      ...contentfulCallToActionFields
    }
    snowmaking {
      ...contentfulCallToActionFields
    }
    skiTracks {
      ...contentfulCallToActionFields
    }
    snowmobileTracks {
      ...contentfulCallToActionFields
    }
    trekkingRoutes {
      ...contentfulCallToActionFields
    }
    snowDepth {
      ...contentfulCallToActionFields
    }
    bikeTrails {
      ...contentfulCallToActionFields
    }
    adventurePark {
      ...contentfulCallToActionFields
    }
    zipline {
      ...contentfulCallToActionFields
    }
    basejump {
      ...contentfulCallToActionFields
    }
    sledTrack {
      ...contentfulCallToActionFields
    }
    trafficPark {
      ...contentfulCallToActionFields
    }
    trampoline {
      ...contentfulCallToActionFields
    }
    slopeRestaurants {
      ...contentfulCallToActionFields
    }
    weather {
      ...contentfulCallToActionFields
    }
    slopeCameras {
      ...contentfulCallToActionFields
    }
  }
`;
